import { styled } from "goober";

export const NOTIFICATION_HEIGHT = "var(--sp-8)";

export const NotificationContainer = styled("div")`
  user-select: none;

  --bg: var(--shade6);
  --text: var(--shade10);
  height: ${NOTIFICATION_HEIGHT};
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: var(--sp-4);

  color: var(--text);
  background: var(--bg);

  &.critical {
    --bg: var(--red);
  }

  &.major {
    --bg: var(--yellow);
  }

  &.minor {
    --bg: var(--blue);
  }

  a.overwrite-button,
  button.overwrite-button {
    height: unset;

    box-shadow: unset;
    text-shadow: unset;
  }
`;
