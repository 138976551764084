import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

import { readState, writePromise } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import {
  addDismissedNotification,
  deleteDismissedNotification,
  hideEphemeralNotification,
  setVolatileKV,
} from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { NotificationContainer } from "@/app/AppHeaderNotification.style.jsx";
import appRefs from "@/app/refs.mjs";
import { classNames } from "@/util/class-names.mjs";
import { internalContainerClass } from "@/util/constants.mjs";
import { EVENT_REFRESH_INITIATED } from "@/util/heap-checker.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function AppHeaderNotification() {
  const { currentPath } = useRoute({ isGlobal: true });
  const { settings, volatile } = useSnapshot(readState);
  const [currentNotification, setCurrentNotification] = useState(null);
  const { t } = useTranslation();

  const appVersion = volatile?.appVersion?.value;
  const dismissedNotifications = settings.dismissedNotifications;
  const notifications = [
    volatile.ephemeralNotification,
    ...(Array.isArray(volatile.app?.notifications)
      ? volatile.app.notifications
      : []),
  ].filter(Boolean);

  const ACTION_MAP = new Map([
    [
      "RESTART_REQUIRED",
      {
        text: t("common:restartApp", "Restart App"),
        fn: async () => {
          dismissedNotification();
          await writePromise();
          await appRefs.userPreferenceUpdatePromise;
          blitzMessage(EVENTS.APP_RESTART);
        },
      },
    ],
  ]);

  const ANY_KEYWORD = "any";

  useEffect(() => {
    if (!notifications?.length) return;

    /* Find previously dismissed notifications that are no longer active and delete them */
    dismissedNotifications
      .filter(
        (notification) =>
          !notifications.map(({ id }) => id).includes(notification),
      )
      .forEach((notification) => deleteDismissedNotification(notification));

    const suitableNotification = notifications
      .sort((a, b) => {
        // empheral notifications which can have no route specified should always be shown first
        if (!a.routes) return -1;
        if (!b.routes) return 1;
        // sort routes by length to ensure that the most specific route is first
        // e.g. a game's profile route is more specific than just the game route
        const longestARouteLength = Math.max(
          ...a.routes.map((route) => route.length),
        );
        const longestBRouteLength = Math.max(
          ...b.routes.map((route) => route.length),
        );
        return longestBRouteLength - longestARouteLength;
      })
      .find((notification) => {
        if (dismissedNotifications.includes(notification.id)) return false;

        if (!notification.routes) return true;

        return notification.routes.some(
          (route) =>
            (currentPath.startsWith(route) || route === ANY_KEYWORD) &&
            !notification.excludedAppVersions.includes(appVersion) &&
            (notification.includedAppVersions.includes(appVersion) ||
              notification.includedAppVersions.includes(ANY_KEYWORD)),
        );
      });

    setCurrentNotification(suitableNotification || null);
  }, [appVersion, currentPath, dismissedNotifications, notifications]);

  useEffect(() => {
    const handleRefreshInitiated = () => {
      setCurrentNotification({
        message: t(
          "common:refreshForOptimalPerformance",
          "App refresh in progress for optimal performance. Thanks for your patience.",
        ),
        severity: "major",
      });
    };
    eventBus.once(EVENT_REFRESH_INITIATED, handleRefreshInitiated);

    return () => {
      eventBus.off(EVENT_REFRESH_INITIATED, handleRefreshInitiated);
    };
  }, [t]);

  const dismissedNotification = useCallback(() => {
    if (currentNotification.id)
      addDismissedNotification(currentNotification.id);
    else hideEphemeralNotification();
    setCurrentNotification(null);
    setVolatileKV("notificationVisible", false);
  }, [currentNotification]);

  if (!currentNotification) return null;

  const action = ACTION_MAP.get(
    Array.from(ACTION_MAP.keys()).find(
      (action) => action === currentNotification.action,
    ),
  );

  return (
    <NotificationContainer
      {...classNames(internalContainerClass, currentNotification?.severity)}
    >
      <span className="type-caption--semi">{currentNotification.message}</span>
      {action && (
        <Button
          bgColor="none"
          bgColorHover="none"
          className="link overwrite-button"
          textClass="type-caption--bold"
          textColor="var(--shade10)"
          textColorHover="unset"
          onClick={action.fn}
        >
          {action.text}
        </Button>
      )}
      {currentNotification.link && (
        <Button
          bgColor="none"
          bgColorHover="none"
          className="link overwrite-button"
          textClass="type-caption--bold"
          textColor="var(--shade10)"
          textColorHover="unset"
          onClick={() =>
            blitzMessage(EVENTS.OPEN_EXTERNAL, currentNotification.link)
          }
        >
          {t("common:learnMore", "Learn More")}
        </Button>
      )}
      {(currentNotification.id || currentNotification.isEphemeral) && (
        <Button
          bgColor="none"
          bgColorHover="none"
          className="dismiss overwrite-button"
          textClass="type-caption--bold"
          textColor="var(--shade10-50)"
          textColorHover="var(--shade10)"
          onClick={dismissedNotification}
        >
          {t("common:dismiss", "Dismiss")}
        </Button>
      )}
    </NotificationContainer>
  );
}

export default AppHeaderNotification;
